<template>
    <div class="m-2 ccss--status-closed">
        <div class="content-container p-10">
            <div>
                <div class="text-center py-5">
                    <h2 class="text-2xl font-bold mb-3">{{ $t('closed.title') }}</h2>
                    <h3 class="text-lg">{{ $t('closed.subtitle') }}</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'status.closed',
}
</script>
